<template>
  <div>
    <ChoosePasswordWindowModal
      :showing-modal="dialog"
      :error="error"
      :email="email"
      :choose-password-loading="loading"
      @show-modal="showModal"
      @update-password="updatePassword"
      @open-terms-and-conditions="openTermAndConditions"
      ref="choosePasswordWindowModal"
    />
    <TermAndConditionsNewModalWithExpands
      :showingModal="dialogTerm"
      :email="email"
      @i-agree-with-temp-conditions="iAgreeWithConditions"
      @email-me="sendEmailMe"
      @show-modal="showModalTerm"
    />
  </div>
</template>

<script>
import ChoosePasswordWindowModal from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/ChoosePasswordWindowModal/ChoosePasswordWindowModal.vue';
import TermAndConditionsNewModalWithExpands from '@innerworks_ds/innerworks-storybook-main/src/stories/WindowModals/TermAndConditionsNewModalWithExpands/TermAndConditionsNewModalWithExpands.vue';
import { mapGetters } from 'vuex';
// import differentsServices from '@helpers/differentsServices';

export default {
  name: 'UpdatePassword',
  components: {
    ChoosePasswordWindowModal, TermAndConditionsNewModalWithExpands,
  },
  data: () => ({
    dialog: true,
    dialogTerm: false,
    error: null,
    email: '',
    loading: false,
  }),
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getInvitationData: 'invitation/getInvitationData',
    }),
  },
  mounted() {
    this.email = this.getCode('email');
  },
  methods: {
    openTermAndConditions() {
      this.dialogTerm = true;
    },
    showModal(value) {
      this.dialog = value;
    },
    showModalTerm(value) {
      this.dialogTerm = value;
    },
    iAgreeWithConditions() {
      this.$refs.choosePasswordWindowModal.agreeWithTermAndConditions();
      this.dialogTerm = false;
    },
    sendEmailMe() {
      this.$api.termAndCondition.sendTermConditionEmail(this.email);
    },
    getCode(param) {
      const urlString = window.location.href;
      const url = new URL(urlString);
      return url.searchParams.get(param);
    },
    updatePassword(data) {
      this.loading = true;

      const formData = {
        ...data,
      };

      formData.code = this.getCode('code');
      formData.email = this.getCode('email');
      const resultPage = this.getCode('result-page');
      const isU2Result = this.getCode('is-u2-result');
      formData.questionnaireId = process.env.QUESTIONNAIRE_ID;
      formData.updateQuestionnaireData = true;
      formData.uniqueId = localStorage.getItem('uniqueId');
      formData.notificationCrowdEmails = this.getProfile.latestNews
        ? this.getProfile.latestNews : false;

      const redirectRoute = this.getProfile.routeBeforeRegister;

      this.$store.dispatch('auth/newPassword', { formData }).then(async () => {
        // const geolocation = await differentsServices.getGeolocation();
        // const getGeoData = await differentsServices.requestSearchGeoPosition(geolocation);
        //
        // if (getGeoData.allowGetGeolocation) {
        //   await this.$api.auth.updateGeoLocationUsers(getGeoData, true, this.getProfile.id);
        // } else if (!getGeoData.allowGetGeolocation) {
        //   await this.$api.auth.updateGeoLocationUsers(getGeoData, false, this.getProfile.id);
        // }

        this.$emit('close-set-password');
        this.loading = false;

        if (Object.keys(this.getInvitationData).length !== 0) {
          this.$router.push('/questionnaire');
        } else if (resultPage) {
          this.$router.push('/result');
          if (isU2Result) {
            this.$store.dispatch('behaviorComponents/changeCrowdTab', false);
          } else {
            this.$store.dispatch('behaviorComponents/changeCrowdTab', true);
          }
        } else if (redirectRoute === '/landing') {
          this.$router.push({
            name: 'main',
            params: {
              slide: 3,
            },
          });
        } else {
          this.$router.push(redirectRoute);
        }
      }).catch((x) => {
        this.error = x.response.data.message;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>
