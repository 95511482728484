<template>
  <div class="personality-carousel-content">
    <UpdatePassword
      @close-set-password="closeUpdatePassword"
      v-if="createPassword"/>
    <LoginAndRegisterWindowModal
      v-if="isOpenLoginRegisterModal"
      @close-login-modal="closeLoginRegisterModal"
    />
    <div class="content-carousel"
      :style="{'justify-content': infoPage ? 'space-between' : 'space-around'}">
      <transition name="fade">
        <Header
          v-show="showHeader"
          :image="smallLogo"
                :showHeaderAllTime="true"
                :style="{background: this.$route.name === 'questionnaire' ?
               'rgb(243,244,246)' : '#F3F4F6'}"
                class="main-container"/>
      </transition>
      <VueSlickCarousel
        class="personality-type-carousel"
        v-bind="settings" ref="carousel"
        @afterChange="changeSlide"
      >
        <template v-if="infoPage">
          <div class="personality-type-carousel__item"
               :style="{'cursor': slides[activeSlider].type === 'landing' ? 'default' : 'cursor'}"
               @click="showNext"
               v-for="slide in slides" :key="slide.id">
            <template v-if="slide.type === 'usual'">
              <img class="personality-type-carousel__img" :src="slide.src" alt="carousel__img">
              <div class="mb-2 carousel-info-page-title" v-html="slide.title">
                {{ slide.title }}
              </div>
              <div class="carousel-info-page-content" v-html="slide.desc">
                {{ slide.desc }}
              </div>
            </template>
            <template v-if="slide.type === 'landing'">
                <WelcomePageToCarousel />
            </template>
          </div>
        </template>
        <template v-else>
          <div class="personality-type-carousel__item
           personality-type-carousel__item_2" v-for="slide in slides" :key="slide.id"
               @click="showNext">
           <div class="h5 text-center carousel-title">
              {{ slide.title }}
            </div>
            <div class="carousel-content" v-html="slide.desc" :style="{color: contentColor}">
              {{ slide.desc }}
            </div>
            <img class="personality-type-carousel__img personality-type-carousel__img_not_info-page"
                 :src="slide.src" alt="carousel__img">
          </div>
        </template>
      </VueSlickCarousel>
        <template v-if="slides[activeSlider].type === 'usual'">
            <div class="dots-carousel">
                      <span class="dots" v-for="sl in filterSliders" :key="sl.id"
                            :class="{'competeSlider': activeSlider >= sl.id,
                              'active-dots-parent': activeSlider === sl.id}">
                        <span :class="{'active-dots': activeSlider === sl.id}"></span>
                      </span>
            </div>
            <div class="carousel-next-slide" :class="{'carousel-next-slide-not-info': !infoPage}">
              <SecondaryButton
                :text="activeSlider === 2 ? slides[2].textButton : slides[0].textButton "
                @click-button="showNext"
              />
            </div>
        </template>
      <transition name="fade">
        <template v-if="slides[activeSlider].type === 'landing'">
          <div class="block-buttons"
            :style="{'justify-content': getProfile.token ? 'flex-end' : 'space-between'}"
          >
            <SecondaryButton
              v-if="!getProfile.token"
              :text="'LOGIN / REGISTER'"
              @click-button="showLoginPage"
            />
            <ActionButton
              :text="getProfile.token ? 'CONTINUE' : 'CONTINUE AS GUEST'"
              @click-button="redirectToInformCarousel"/>
          </div>
        </template>
      </transition>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import SecondaryButton from '@innerworks_ds/innerworks-storybook-main/src/stories/SecondaryButton/SecondaryButton.vue';
import ActionButton from '@innerworks_ds/innerworks-storybook-main/src/stories/Button/ActionButton.vue';
import { mapGetters } from 'vuex';
import LoginAndRegisterWindowModal from '@components/LoginAndRegisterModal/LoginAndRegisterWindowModal.vue';
import UpdatePassword from '@components/UpdatePassword/UpdatePassword.vue';
import Header from '@components/Header/Header.vue';
import smallLogo from '@assets/small_logo.svg';
import WelcomePageToCarousel from './WelcomePageToCarousel.vue';

export default {
  components: {
    WelcomePageToCarousel,
    VueSlickCarousel,
    SecondaryButton,
    ActionButton,
    LoginAndRegisterWindowModal,
    UpdatePassword,
    Header,
  },
  props: {
    slides: {
      type: Array,
    },
    infoPage: {
      type: Boolean,
      default() {
        return true;
      },
    },
    invitation: {
      type: Boolean,
      default() {
        return false;
      },
    },
    contentColor: {
      type: String,
      default() {
        return '';
      },
    },
    redirectPath: {
      type: String,
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.params.action === 'login' && !this.getProfile.token) {
        this.isOpenLoginRegisterModal = true;
        this.gotToSlide(3);
      } else if (this.$route.params.action === 'activate' && !this.getProfile.token) {
        this.gotToSlide(3);
        this.$api.auth.checkActivatedAccount(this.getCode('email'))
          .then((registeredUser) => {
            if (registeredUser) {
              this.isOpenLoginRegisterModal = true;
            } else {
              this.createPassword = true;
            }
          });
      } else if (this.$route.params.slide) {
        this.gotToSlide(this.$route.params.slide);
        this.autoplay();
      } else {
        if (this.getProfile.token) {
          this.gotToSlide(3);
        }
        this.autoplay();
      }
    }, 200);
  },
  data: () => ({
    smallLogo,
    activeSlider: 0,
    autoplayVar: null,
    email: '',
    loginPage: false,
    isOpenLoginRegisterModal: false,
    createPassword: false,
    showHeader: false,
    settings: {
      centerPadding: '0px',
      infinite: false,
      slidesToShow: 1,
      speed: 500,
      draggable: false,
      swipe: false,
    },
  }),
  computed: {
    filterSliders() {
      return this.slides.filter(item => item.type === 'usual');
    },
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getInvitationData: 'invitation/getInvitationData',
    }),
  },
  destroyed() {
    clearTimeout(this.autoplayVar);
  },
  watch: {
    activeSlider(value) {
      if (value && this.slides[value].type === 'landing') {
        this.$store.dispatch('showComponent/showHeaderMain');
        this.showHeader = true;
      } else {
        this.showHeader = false;
        this.$store.dispatch('showComponent/hideHeader');
      }
    },
  },
  methods: {
    showNext() {
      if (this.activeSlider === 2 && !this.infoPage) {
        clearTimeout(this.autoplayVar);
        this.$router.push(this.redirectPath);
      } else if (this.$refs.carousel) {
        this.$refs.carousel.next();
      }
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
    changeSlide(numberSlide) {
      clearTimeout(this.autoplayVar);
      this.autoplay();
      this.activeSlider = numberSlide;
      this.$emit('change', numberSlide);
    },
    autoplay() {
      const that = this;

      this.autoplayVar = setTimeout(function tick() {
        that.showNext();
        that.autoplayVar = setTimeout(tick, 6000);
      }, 6000);
    },
    getCode(param) {
      const urlString = window.location.href;
      const url = new URL(urlString);
      return url.searchParams.get(param);
    },
    closeUpdatePassword() {
      this.createPassword = false;
    },
    closeLoginRegisterModal() {
      this.isOpenLoginRegisterModal = false;
    },
    redirectToInformCarousel() {
      if (Object.keys(this.getInvitationData).length === 0) {
        this.$router.push('/ask-name');
      } else {
        this.$router.push('/questionnaire');
      }
    },
    showLoginPage() {
      this.isOpenLoginRegisterModal = true;
    },
    initialSlider() {
      const { slide } = this.$route.params;
      if (slide) {
        this.gotToSlide(slide);
      }
    },
    gotToSlide(numberSlide) {
      // eslint-disable-next-line no-plusplus
      this.$refs.carousel.goTo(numberSlide);
      this.changeSlide(numberSlide);
    },
  },
};
</script>
<style lang="scss" scoped>
  .personality-carousel-content {
    height: 100%;
  }

  .block-buttons {
    position: sticky;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    width: 100%;
    max-width: 480px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #F3F4F6;
  }
  .block-space {
    height: 100%;
  }
  @media (max-height: 590px)  {
    .block-buttons {
      position: static;
    }
  }
  .main-container {
    position: absolute;
  }
</style>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
  .personality-type-carousel__item{
    cursor: pointer;
    font-family: $newDefaultFont;
  }
  .personality-type-carousel{
    overflow: hidden;
    width: 100%;
    .slick-active{
      .personality-type-carousel__content{
        transform: scale(1.05);
      }
    }
  }
  .slick-slide {
    /*width: 480px !important;*/
  }
  .slick-dots {
    button:before {
      color: #7811C9 !important;
    }
  }
  .content-carousel {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
  .carousel-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.1em;
    color: rgba(50, 9, 89, 0.8);
  }
  .carousel-content {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 44px;
    text-align: center;
    color: rgba(50, 9, 89, 0.7);
    margin-top: 15px;
  }
  .carousel-info-page-content {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: rgba(34, 34, 34, 0.7);
    padding-left: 20px;
    padding-right: 20px;
  }
  .carousel-info-page-title {
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    color: rgba(50, 9, 89, 0.8);
  }
  .carousel-content-info-page {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: rgba(34, 34, 34, 0.7);
    padding-left: 30px;
    padding-right: 30px;
  }

  .slick-dots {
    bottom: 20%;
  }

  .personality-type-carousel__img{
    height: 210px;
    margin: 23% auto 100px;
    box-sizing: border-box;
    max-width:100%;
  }
  .personality-type-carousel__img_not_info-page {
    margin: 18% auto 53px;
  }
  .personality-type-carousel__controls{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .personality-type-carousel__controls-text{
    color: $txtColor2;
    font-family: $defaultFont;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
  }
  .personality-type-carousel__item_2 {
    padding: 10px
  }
  .personality-type-carousel__button{
    padding: 0;
    background-color: transparent;
    border: none;
    color: $txtColor2;
    font-size: 32px;
    line-height: 8px;
    width: 24px;
    height: 24px;
    svg{
      fill: $txtColor2;
    }
    &:hover, &:focus{
      outline: none;
    }
  }
  .space {
    height: 50px;
  }
  .carousel-next-slide {
    margin-bottom: 20%;
    width: 100%;
  }
  .carousel-next-slide-not-info {
    margin-bottom: 8%;
  }
  .dots-carousel {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding-bottom: 5px;

    .dots {
      box-sizing: content-box;
      border-radius: 10px;
      height: 6px;
      width: 6px;
      background-color: #D7D8D9;
      margin-left: 5px;
    }
    .active-dots {
      background-color: #7811C9 !important;
      animation: animate-dots 5500ms forwards cubic-bezier(0.1, -0.6, 0.2, 0);
      position: absolute;
      width: 24px;
      height: 6px;
      border-radius: 10px;
    }
    .active-dots-parent {
      background-color: #D7D8D9 !important;
      width: 40px;
      position: relative;
    }
    .competeSlider {
      background-color: #7811C9;
    }
  }
  @keyframes animate-dots {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  @media (max-height: $xxxsMaxHeight) {
    .personality-type-carousel__img {
      margin-top: calc(72px + 40px);
      margin-bottom: 30px;
      height: 200px;
    }
    .carousel-footer {
      bottom: 0;
      font-size: 14px;
    }
    .dots-carousel {
      bottom: 25px;
    }
  }

  @media (max-height: $xsMaxHeight) {
    .personality-type-carousel__img {
      margin-top: calc(72px + 40px);
      margin-bottom: 30px;
      height: 150px;
    }
    .carousel-footer {
      bottom: 0;
      font-size: 14px;
    }
    .dots-carousel {
      bottom: 25px;
    }
  }

  @media (max-height: $xsMaxHeight) {
    .personality-type-carousel__img_not_info-page {
      margin-top: 50px;
      margin-bottom: 30px;
      height: 150px;
    }
    .carousel-footer {
      bottom: 0;
      font-size: 14px;
    }
    .dots-carousel {
      bottom: 25px;
    }
  }

</style>
