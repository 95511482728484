<template>
  <div>
<!--    <Header :image="smallLogo"-->
<!--            :showHeaderAllTime="true"-->
<!--            :style="{background: this.$route.name === 'questionnaire' ?-->
<!--             'rgb(243,244,246)' : '#F3F4F6'}"-->
<!--            class="main-container"/>-->
    <div class="welcome-page">
      <img :src="welcomeImage" alt="welcome-image" class="welcome-image"/>
      <div class="welcome-page-text">
        READY TO START?
      </div>
    </div>
    </div>
</template>

<script>
import welcomeImage from '@assets/welcome-image.svg';
import personIcon from '@assets/person-icon.svg';
import { mapGetters } from 'vuex';
import smallLogo from '@assets/small_logo.svg';
// import Header from '@components/Header/Header.vue';

export default {
  name: 'WelcomePageToCarousel',
  components: {
    // Header,
  },
  data: () => ({
    smallLogo,
    welcomeImage,
    personIcon,
    email: '',
    loginPage: false,
    isOpenLoginRegisterModal: false,
    createPassword: false,
  }),
  created() {
    if (this.$route.params.login) {
      this.isOpenLoginRegisterModal = true;
    } else if (this.$route.name === 'activate' && !this.getProfile.token) {
      this.$api.auth.checkActivatedAccount(this.getCode('email'))
        .then((registeredUser) => {
          if (registeredUser) {
            this.isOpenLoginRegisterModal = true;
          } else {
            this.createPassword = true;
          }
        });
    }
  },
  computed: {
    ...mapGetters({
      getProfile: 'auth/getProfile',
      getInvitationData: 'invitation/getInvitationData',
    }),
  },
  watch: {
    login: {
      immediate: true,
      handler() {
      },
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.landing-page {
  height: 100%;
}
.block-buttons {
  position: fixed;
  width: 100%;
  bottom: 0;
  max-width: 480px;
  transform: translate(-50%, 0);
  left: 87.5%;
  min-height: 234px;
  background-color: #F3F4F6;
}
.block-space {
  height: 234px;
}

@media screen and (orientation:landscape) {
  .block-buttons {
    position: static;
    width: 100%;
    bottom: 0;
    max-width: 480px;
    transform: none;
    left: 50%;
    min-height: 234px;
  }
}

@media screen and (orientation:landscape) {
  .block-buttons {
    position: static;
    width: 100%;
    bottom: 0;
    max-width: 480px;
    transform: none;
    left: 50%;
    min-height: 234px;
  }
}

@media (max-height: 580px)  {
  .block-buttons {
    position: static;
    width: 100%;
    bottom: 0;
    max-width: 480px;
    transform: none;
    left: 50%;
    min-height: 234px;
  }
}

.welcome-page {
  font-family: $newDefaultFont;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 23%;
}
.title-welcome-page {
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  color: rgba(50, 9, 89, 0.8);
  margin-bottom: 90px;
  margin-top: 60px;
}
.welcome-page-text {
  margin-top: 60px;
  font-family: 'Roboto',serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #7811C9;

}
.custom-input {
  background-color: white;
  border-radius: 30px;
  width: 100%;
  height: 46px;
  border: 1px solid #EAEBF1;
  padding-left: 41px;
  cursor: text;
  &::placeholder {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #686F9B;
  }

  &:-ms-input-placeholder {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #686F9B;
  }

  &::-ms-input-placeholder {
    font-family: $newDefaultFont;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #686F9B;
  }
}
.custom-input:focus {
  outline: none;
}
.custom-input-block {
  position: relative;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 110px;
}
.person-icon {
  position: absolute;
  filter: invert(60%) sepia(53%) saturate(302%) hue-rotate(194deg) brightness(98%) contrast(86%);
  top: 16px;
  left: 45px;
  width: 16px;
  height: 16px;
}

.personality-type-carousel__item {
  height: calc(100vh - 75px);
  position: relative;
}
.personality-type-carousel__item__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
  padding-bottom: 150px;
  justify-content: center;
}
.personality-type-carousel__item__login {
  height: calc(100vh - 75px);
  position: relative;
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  overflow: auto;
}
.button-custom-secondary {
  position: absolute;
  bottom: 130px;
}

.login-welcome-page {
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #7811C9;
  cursor: pointer;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}
.space-welcome {
  margin-bottom: 250px;
}
.personality-type-carousel {
  overflow: hidden;
  width: 100%;

  .slick-active {
    .personality-type-carousel__content {
      transform: scale(1.05);
    }
  }
}

.welcome-image {
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  height: 100%;
  max-height: 324px;
}


@media (max-height: $xxxsMaxHeight) {
  .welcome-image {
    max-width: 215px;
  }
  .title-welcome-page {
    margin-bottom: 40px;
  }
}

@media (max-height: $xsMaxHeight) {
  .title-welcome-page {
    margin-bottom: 30px;
  }
}

@media (max-height: $smallMaxHeight) {
  .welcome-image {
    max-height: 150px;
    padding-top: 0;
  }
  .login-welcome-page {
    bottom: 60px;
  }
  .title-welcome-page {
    margin-top: 20px;
  }
  .personality-type-carousel__item__content {
    padding-bottom: 40px;
  }
}

@media (max-height: 280px) {
  .welcome-image {
    max-height: 100px;
    padding-top: 0;
  }
  .login-welcome-page {
    bottom: 50px;
  }
  .title-welcome-page {
    margin-top: 5px;
  }
}
</style>
